<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Campaign Statistics Reporting</h2>

        <div style="background-color:white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group  mb-2 mr-sm-2">
                            <!--<label >Name</label>-->
                            <select class="form-control" v-model="sort.name">
                                <option value="">-- Sort By Name --</option>
                                <option value="asc">-- A-Z --</option>
                                <option value="desc">-- Z-A --</option>
                            </select>
                        </div>
                        <div class="form-group  mb-2 mr-sm-2">
                            <!-- <label >Daily</label>-->
                            <select class="form-control" v-model="sort.daily">
                                <option value="">-- Sort By Daily Max --</option>
                                <option value="asc">-- Ascending --</option>
                                <option value="desc">-- Descending --</option>
                            </select>
                        </div>
                        <div class="form-group  mb-2 mr-sm-2">
                            <!-- <label >Max</label>-->
                            <select class="form-control" v-model="sort.max">
                                <option value="">-- Sort By Max --</option>
                                <option value="asc">-- Ascending --</option>
                                <option value="desc">-- Descending --</option>
                            </select>
                        </div>
                        <div class="form-group  mb-2 mr-sm-2">
                            <!--<label >Hits</label>-->
                            <select class="form-control" v-model="sort.hits">
                                <option value="">-- Sort By Hits --</option>
                                <option value="asc">-- Ascending --</option>
                                <option value="desc">-- Descending --</option>
                            </select>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.client" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client.value" :value="client.value">{{ client.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.product" class="form-control">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in dropdownProducts" :key="product.value" :value="product.value">{{ product.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <button type="button" @click="search" style="background-color: #383838" class="btn text-white"><i class="fa fa-search" title="Search"></i> Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="report">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 campaign container-fluid" v-for="data in items" v-bind:key="data._id">
                        <!--                    <div class="p-2 mb-2" :class="{'bg-success border-success text-white':Number(data.target) > 0 && Number(data.tracking) >= Number(data.target), 'bg-white border':Number(data.target) >= 0 && Number(data.tracking) < Number(data.target)}">-->
                        <!--                        <div class="name">{{data.name.substr(0, 20)}}</div>-->
                        <!--                        <div class="hits"><b>{{ Number(data.tracking).toLocaleString()}} <small>Hits</small></b></div>-->
                        <!--                        <div class="daily-max"><small>Daily: {{Number(data.maxDailyClicks).toLocaleString()}} / Max: {{Number(data.target).toLocaleString()}}</small></div>-->
                       <!--                    </div>-->
                        <div class="card text-white bg-dark mb-3 container-fluid " style="max-width: 18rem;">
                            <div class="card-header">{{data.name.substr(0, 20)}}</div>
                            <div class="card-body">
                                <h5 class="card-title">Hits: {{Number(data.tracking || 0).toLocaleString()}}</h5>
                                <p class="card-text">Daily: {{Number(data.maxDailyClicks || 0).toLocaleString()}} / Max: {{Number(data.target || 0).toLocaleString()}}</p>
                            </div>
                        </div>
                    </div>
                    <!--                <div class="col-12 col-sm-9">
                                        <infinite-loading @infinite="search">
                                            <div slot="spinner">
                                                <b-spinner type="grow" label="Loading..." variant="primary"></b-spinner>
                                                <h3>Fetching......</h3>
                                            </div>
                                            <div slot="no-more"><h3>No more results were found......</h3></div>
                                            <div slot="no-results"><h3>No results were found......</h3></div>
                                        </infinite-loading>
                                    </div>-->
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
//import InfiniteLoading from 'vue-infinite-loading';

export default {
    data: function(){
        return {
            searching: false,
            //max: 102,
            //page: 0,
            //infinite: true,
            items:[],
            filter: {
                client: '',
                product: '',
            },
            dropdownClients: [],
            dropdownProducts: [],
            sort:{
                name:'',
                daily:'',
                max:'',
                hits:''
            },
        }
    },
    components:{
        //InfiniteLoading
    },
    created: function(){
        this.load();
        this.GetClientsList();
        this.GetProductList();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        load: function(){
            this.$root.preloader = false;
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        GetProductList: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/product/crawler`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownProducts = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        search: function(/*state*/){
            this.searching = true;
            this.$root.preloader = true;
            this.items = [];
            this.page++;

            /*if(state instanceof MouseEvent || typeof state === "undefined") {
                this.page = 0;
                this.items = [];
                this.$root.preloader = true;
                this.infinite = false;
            }*/

            const request = {'apikey': this.$root.apikey}

            //request.page = page
            //request.page = this.page
            //request.max = this.max
            request.sortName = this.sort.name
            request.sortDaily = this.sort.daily
            request.sortMax = this.sort.max
            request.sortHits = this.sort.hits
            request.client_id = this.filter.client
            request.product_id = this.filter.product

            return axios.get(`${this.$root.serverUrl}/admin/reports/campaign/statistics`, {params:request}).then(function(resp){
                const results = resp.data.data.campaigns.list;
                if (typeof results !== "undefined" && results.length > 0) {
                    results.forEach(item => this.items.push(item));
                    //if(this.infinite) state.loaded();
                } else {
                    //if(this.infinite) state.complete();
                }
                /*if(!this.infinite) {
                    this.$root.preloader = false;
                }*/
                this.searching = false;
                this.$root.preloader = false;
                //this.infinite = true;
            }.bind(this)).catch(function (err) {
                swal({title:'Oops', text: err.message, icon: 'error'});
               /* if(!this.infinite) {
                    this.$root.preloader = false;
                }else{
                    state.complete();
                }*/
                this.searching = false;
                this.$root.preloader = false;
                //this.infinite = true;
            }.bind(this));
        }
    }
}
</script>

<style scoped>

</style>
